import React from "react";
import { Link } from "@chakra-ui/react";
import { Link as RLink } from "react-router-dom";
import logo from "../assets/logo.png";
import LazyImage from "./LazyImage";

export default function Logo(props) {
  return (
    <Link
      textDecoration="none"
      _hover={{ "text-decoration": "none" }}
      _focus={{ boxShadow: "none!important" }}
      {...props}
      as={RLink}
      to={props.to || "/"}
    >
      <LazyImage alt="Either" src={logo} draggable={false} w="150px" />
    </Link>
  );
}
