import { extendTheme } from "@chakra-ui/react";
import "./css/theme.css";

const config = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
  cssVarPrefix: "either",
};

const styles = {
  global: {
    body: {
      bg: "rgb(24, 24, 24)",
    },
  },
};

const components = {
  Input: {
    defaultProps: {
      focusBorderColor: "#f9279d",
    },
  },
  Textarea: {
    defaultProps: {
      focusBorderColor: "#f9279d",
    },
  },
  Select: {
    defaultProps: {
      focusBorderColor: "#f9279d",
    },
  },
  Radio: {
    defaultProps: {
      colorScheme: "teal",
    },
  },
  FormLabel: {
    defaultProps: {
      fontWeight: "bold",
    },
  },
  Modal: {
    defaultProps: {
      isCentered: true,
    },
  },
};

const colors = {
  main: "#f9279d", 
  teal: {
    50: "#f9279d",
    100: "#f9279d",
    200: "#f9279d",
    300: "#f9279d",
    400: "#f9279d",
    500: "#f9279d",
    600: "#f9279d",
    700: "#f9279d",
    800: "#f9279d",
    900: "#f9279d",
  },
};

const fonts = {
  heading: "Stolzl Book, sans-serif",
  body: "Stolzl Book, sans-serif",
};

const theme = extendTheme({
  config,
  styles,
  components,
  colors,
  fonts,
});

export default theme;
