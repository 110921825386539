import { Progress, Box } from "@chakra-ui/react";
import {Title} from 'react-head-meta'

export default function Loader() {
  return (
    <Box m="40px">
      <Title title='просто Music' />
      <Progress size="xs" colorScheme="teal" isIndeterminate />
    </Box>
  );
}
