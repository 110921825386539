import Auth from "../check/Auth";
import Admin from "../check/Admin";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import ScrollToTop from "../components/ScrollToTop";
import {
  Box,
  Container,
  Center,
  Spinner,
  Heading,
  Text,
  Stack,
  useColorMode ,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Title } from "react-head-meta";

import Header from "../components/Header";
import AdminHeader from "../components/AdminHeader";
import Loader from "../components/Loader";

import React, { Suspense, useEffect } from "react";

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

const NotFound = React.lazy(() => import("../pages/NotFound"));
const Login = React.lazy(() => import("../pages/Login"));

// user
const Main = React.lazy(() => import("../pages/Auth/Main"));
const News = React.lazy(() => import("../pages/Auth/News"));
const Profile = React.lazy(() => import("../pages/Auth/Profile"));
const Create = React.lazy(() => import("../pages/Auth/Create"));
const Catalog = React.lazy(() => import("../pages/Auth/Catalog"));
const Drafts = React.lazy(() => import("../pages/Auth/Drafts"));
const Moderation = React.lazy(() => import("../pages/Auth/Moderation"));
const Deleting = React.lazy(() => import("../pages/Auth/Deleting"));
const Edit = React.lazy(() => import("../pages/Auth/Edit"));
const View = React.lazy(() => import("../pages/Auth/View"));
const Finance = React.lazy(() => import("../pages/Auth/Finance"));
const NewsView = React.lazy(() => import("../pages/Auth/NewsView"));
const Analytics = React.lazy(() => import("../pages/Auth/Analytics"));
const Links = React.lazy(() => import("../pages/Auth/Links"));
const LinkEdit = React.lazy(() => import("../pages/Auth/LinkEdit"));
const Lyrics = React.lazy(() => import("../pages/Auth/Lyrics/Main"));
const Subaccounts = React.lazy(() => import("../pages/Auth/Subaccounts"));
const SubaccountsCreate = React.lazy(() =>
  import("../pages/Auth/subaccounts/Create")
);
const SubaccountsEdit = React.lazy(() =>
  import("../pages/Auth/subaccounts/Edit")
);

// admin
const AMain = React.lazy(() => import("../pages/Admin/Main"));
const ACatalog = React.lazy(() => import("../pages/Admin/Catalog"));
const AModeration = React.lazy(() => import("../pages/Admin/Moderation"));
const ADeleting = React.lazy(() => import("../pages/Admin/Deleting"));
const AView = React.lazy(() => import("../pages/Admin/View"));
const ANews = React.lazy(() => import("../pages/Admin/News"));
const AUsers = React.lazy(() => import("../pages/Admin/Users"));
const ANewUser = React.lazy(() => import("../pages/Admin/NewUser"));
const AFinance = React.lazy(() => import("../pages/Admin/Finance"));
const AAddReport = React.lazy(() => import("../pages/Admin/AddReport"));
const ANewsView = React.lazy(() => import("../pages/Admin/NewsView"));
const AAnalytics = React.lazy(() => import("../pages/Admin/Analytics"));
const APosters = React.lazy(() => import("../pages/Admin/Posters"));

export default function AllRoutes() {
  const auth = Auth();
  const admin = Admin();

  let location = useLocation();
  let url = location.pathname;

  const { t } = useTranslation();

  const { setColorMode } = useColorMode();

  useEffect(() => {
    setColorMode('dark');
  }, [setColorMode]);

  return (
    <>
      {(auth && (
        <>
          {admin && (
            <>
              {url.includes("/admin") && (
                <AdminHeader>
                  <Box m="10px" ml={["0px", "40px"]}>
                    <ScrollToTop />
                    <Routes>
                      <Route
                        path="/admin"
                        element={<Navigate to="/admin/dashboard" />}
                      />
                      <Route
                        path="/admin/dashboard"
                        element={
                          <Suspense fallback={<Loader />}>
                            <Title title="Обзор – просто Music" />
                            <AMain />
                          </Suspense>
                        }
                      />
                      <Route
                        path="/admin/music"
                        element={<Navigate to="/admin/music/releases" />}
                      />

                      <Route
                        path="/admin/music/:id"
                        element={<Navigate to="/admin/music/releases" />}
                      />
                      <Route
                        path="/admin/music/releases"
                        element={
                          <Suspense fallback={<Loader />}>
                            <Title title="Релизы – просто Music" />
                            <ACatalog />
                          </Suspense>
                        }
                      />
                      <Route
                        path="/admin/music/moderation"
                        element={
                          <Suspense fallback={<Loader />}>
                            <Title title="На модерации – просто Music" />
                            <AModeration />
                          </Suspense>
                        }
                      />
                      <Route
                        path="/admin/music/deleting/reload"
                        element={<Navigate to="/admin/music/deleting" />}
                      />
                      <Route
                        path="/admin/music/deleting"
                        element={
                          <Suspense fallback={<Loader />}>
                            <Title title="На удалении – просто Music" />
                            <ADeleting />
                          </Suspense>
                        }
                      />

                      <Route
                        path="/admin/music/:id/view"
                        element={
                          <Suspense fallback={<Loader />}>
                            <Title title="Информация о релизе – просто Music" />
                            <AView />
                          </Suspense>
                        }
                      />
                      <Route
                        path="/admin/news"
                        element={
                          <Suspense fallback={<Loader />}>
                            <Title title="Новости – просто Music" />
                            <ANews />
                          </Suspense>
                        }
                      />
                      <Route
                        path="/admin/news/:id"
                        element={
                          <Suspense fallback={<Loader />}>
                            <Title title="просто Music" />
                            <ANewsView />
                          </Suspense>
                        }
                      />
                      <Route
                        path="/admin/users"
                        element={
                          <Suspense fallback={<Loader />}>
                            <Title title="Пользователи – просто Music" />
                            <AUsers />
                          </Suspense>
                        }
                      />
                      <Route
                        path="/admin/users/create"
                        element={
                          <Suspense fallback={<Loader />}>
                            <Title title="Новый пользователь – просто Music" />
                            <ANewUser />
                          </Suspense>
                        }
                      />
                      <Route
                        path="/admin/finance"
                        element={
                          <Suspense fallback={<Loader />}>
                            <Title title="Финансы – просто Music" />
                            <AFinance />
                          </Suspense>
                        }
                      />
                      <Route
                        path="/admin/finance/add"
                        element={
                          <Suspense fallback={<Loader />}>
                            <Title title="Финансы – просто Music" />
                            <AAddReport />
                          </Suspense>
                        }
                      />
                      <Route
                        path="/admin/analytics"
                        element={
                          <Suspense fallback={<Loader />}>
                            <Title title="Аналитика – просто Music" />
                            <AAnalytics />
                          </Suspense>
                        }
                      />
                      <Route
                        path="/admin/marketing/posters"
                        element={
                          <Suspense fallback={<Loader />}>
                            <Title title="Постеры – просто Music" />
                            <APosters />
                          </Suspense>
                        }
                      />
                      <Route
                        path="*"
                        element={
                          <Suspense fallback={<Loader />}>
                            <NotFound />
                          </Suspense>
                        }
                      />
                    </Routes>
                  </Box>
                </AdminHeader>
              )}
            </>
          )}
          {!url.includes("/admin") && (
            <Header>
              <Box m="10px" ml={["0px", "40px"]}>
                <ScrollToTop />
                <Routes>
                  <Route path="/" element={<Navigate to="/dashboard" />} />
                  <Route
                    path="/dashboard"
                    element={
                      <Suspense fallback={<Loader />}>
                        <Main />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/news"
                    element={
                      <Suspense fallback={<Loader />}>
                        <Title title={`${t("menu.news")} – просто Music`} />
                        <News />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/news/:id"
                    element={
                      <Suspense fallback={<Loader />}>
                        <Title title={`просто Music`} />
                        <NewsView />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/sub-accounts"
                    element={
                      <Suspense fallback={<Loader />}>
                        <Title
                          title={`${t("menu.subaccounts")} – просто Music`}
                        />
                        <Subaccounts />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/sub-accounts/create"
                    element={
                      <Suspense fallback={<Loader />}>
                        <Title title={`просто Music`} />
                        <SubaccountsCreate />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/sub-accounts/edit/:userId"
                    element={
                      <Suspense fallback={<Loader />}>
                        <Title title={`просто Music`} />
                        <SubaccountsEdit />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/account"
                    element={<Navigate to="/account/profile-edit" />}
                  />
                  <Route
                    path="/account/oauth-login"
                    element={<Navigate to="/" />}
                  />
                  <Route
                    path="/account/profile-edit"
                    element={
                      <Suspense fallback={<Loader />}>
                        <Title title={`${t("menu.account")} – просто Music`} />
                        <Profile />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/music"
                    element={<Navigate to="/music/releases" />}
                  />
                  <Route
                    path="/music/create"
                    element={
                      <Suspense fallback={<Loader />}>
                        <Create />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/music/:id"
                    element={<Navigate to="/music/releases" />}
                  />
                  <Route
                    path="/music/releases"
                    element={
                      <Suspense fallback={<Loader />}>
                        <Title
                          title={`${t("menu.releases")} – просто Music`}
                        />
                        <Catalog />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/music/drafts/reload"
                    element={<Navigate to="/music/drafts" />}
                  />
                  <Route
                    path="/music/drafts"
                    element={
                      <Suspense fallback={<Loader />}>
                        <Title title={`${t("menu.drafts")} – просто Music`} />
                        <Drafts />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/music/moderation"
                    element={
                      <Suspense fallback={<Loader />}>
                        <Title
                          title={`${t("menu.moderation")} – просто Music`}
                        />
                        <Moderation />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/music/deleting"
                    element={
                      <Suspense fallback={<Loader />}>
                        <Title
                          title={`${t("menu.deleting")} – просто Music`}
                        />
                        <Deleting />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/music/:id/edit"
                    element={
                      <Suspense fallback={<Loader />}>
                        <Edit />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/music/:id/view"
                    element={
                      <Suspense fallback={<Loader />}>
                        <Title
                          title={`${t("view.release.title")} – просто Music`}
                        />
                        <View />
                      </Suspense>
                    }
                  />

                  <Route
                    path="/finance"
                    element={
                      <Suspense fallback={<Loader />}>
                        <Title title={`${t("menu.finance")} – просто Music`} />
                        <Finance />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/lyrics"
                    element={
                      <Suspense fallback={<Loader />}>
                        <Title title={`${t("menu.lyrics")} – просто Music`} />
                        <Lyrics />
                      </Suspense>
                    }
                  />

                  <Route
                    path="/analytics"
                    element={
                      <Suspense fallback={<Loader />}>
                        <Title
                          title={`${t("menu.analytics")} – просто Music`}
                        />
                        <Analytics />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/marketing"
                    element={<Navigate to="/marketing/promo-links" />}
                  />

                  <Route
                    path="/marketing/promo-links"
                    element={
                      <Suspense fallback={<Loader />}>
                        <Title title={`${t("menu.links")} – просто Music`} />
                        <Links />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/marketing/promo-links/:id/edit"
                    element={
                      <Suspense fallback={<Loader />}>
                        <Title title={`просто Music`} />
                        <LinkEdit />
                      </Suspense>
                    }
                  />

                  <Route
                    path="*"
                    element={
                      <Suspense fallback={<Loader />}>
                        <NotFound />
                      </Suspense>
                    }
                  />
                </Routes>
              </Box>
            </Header>
          )}
        </>
      )) || (
        <>
          <ScrollToTop />
          <Routes>
            <Route
              path="/account/oauth-login"
              element={
                <Suspense
                  fallback={
                    <Container
                      mt="20px"
                      maxW="lg"
                      style={{ top: "50%" }}
                      alignItems="center"
                      spacing={{ base: 4 }}
                      py={{ base: 20, md: 36 }}
                    >
                      <Center>
                        <Spinner color="main" size="xl" />
                      </Center>
                    </Container>
                  }
                >
                  <Login />
                </Suspense>
              }
            ></Route>
            <Route path="*" element={<Navigate to="/account/oauth-login" />} />
          </Routes>
        </>
      )}
    </>
  );
}
