import React from "react";
import ReactDOM from "react-dom/client";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import theme from "./theme";
import "./i18n";
import AllRoutes from "./AllRoutes";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<BrowserRouter>
		<ChakraProvider theme={theme}>
			<AllRoutes />
		</ChakraProvider>
	</BrowserRouter>
);
