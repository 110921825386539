import React, { ReactNode } from "react";
import {
	IconButton,
	Avatar,
	Box,
	CloseButton,
	Flex,
	HStack,
	VStack,
	Icon,
	useColorModeValue,
	Drawer,
	DrawerContent,
	Text,
	useDisclosure,
	BoxProps,
	FlexProps,
	Menu,
	MenuButton,
	MenuDivider,
	MenuItem,
	MenuList,
	List,
	ListItem,
	ListIcon,
	Stack,
	Link,
	Collapse,
	Badge,
} from "@chakra-ui/react";
import { HiOutlineMusicNote } from "react-icons/hi";
import { FiMenu, FiChevronDown, FiHome, FiUsers } from "react-icons/fi";
import { TbSquarePlus } from "react-icons/tb";
import Logo from "./Logo";
import { Link as RLink, useLocation } from "react-router-dom";
import { BsNewspaper } from "react-icons/bs";
import { BiDollar } from "react-icons/bi";
import axios from "axios";
import apiUrl from "../config/apiUrl";
import getToken from "../config/getToken";
import Cookies from "js-cookie";
import { useState, useEffect } from "react";
import { MdOutlineSpaceDashboard } from "react-icons/md";
import { IoAnalyticsOutline } from "react-icons/io5";
import { BsMegaphone } from "react-icons/bs";

export default function SidebarWithHeader({ children }) {
	const { isOpen, onOpen, onClose } = useDisclosure();
	return (
		<Box minH="100vh">
			<SidebarContent
				onClose={() => onClose}
				display={{ base: "none", md: "block" }}
			/>
			<Drawer
				autoFocus={false}
				isOpen={isOpen}
				placement="left"
				onClose={onClose}
				returnFocusOnClose={false}
				onOverlayClick={onClose}
				size="xs"
			>
				<DrawerContent>
					<SidebarContent onClose={onClose} />
				</DrawerContent>
			</Drawer>
			{/* mobilenav */}
			<MobileNav onOpen={onOpen} />
			<Box ml={{ base: 0, md: 60 }} p="4">
				{children}
			</Box>
		</Box>
	);
}

const SidebarContent = ({ onClose, ...rest }) => {
	const [count, setCount] = React.useState({
		releases: 0,
		deleting: 0,
		moderation: 0,
	});

	let location = useLocation();
	let url = location.pathname;

	React.useEffect(() => {
		try {
			const getCount = async () => {
				const { data: total } = await axios.get(
					`${apiUrl()}/admin/releases_count`,
					{
						headers: {
							authorization: `Bearer ${getToken()}`,
						},
					}
				);
				setCount(total.count);
			};

			getCount();
		} catch (e) {
			console.error(e);
		}
	}, [url]);

	const LinkItems = [
		{ name: "Обзор", icon: MdOutlineSpaceDashboard, to: "/admin/dashboard" },
		{
			name: "Каталог",
			icon: HiOutlineMusicNote,
			isCollapse: true,
			items: [
				{
					name: "Релизы",
					to: "/admin/music/releases",
					count: count["releases"],
				},
				{
					name: "Модерация",
					to: "/admin/music/moderation",
					count: count["moderation"],
				},
				{
					name: "На удалении",
					to: "/admin/music/deleting",
					isLast: true,
					count: count["deleting"],
				},
			],
		},
		{
			name: "Пользователи",
			icon: FiUsers,
			isCollapse: true,
			items: [
				{ name: "Пользователи", to: "/admin/users" },
				{ name: "Новый пользователь", to: "/admin/users/create", isLast: true },
			],
		},

		{ name: "Финансы", icon: BiDollar, to: "/admin/finance" },
		{ name: "Аналитика", icon: IoAnalyticsOutline, to: "/admin/analytics" },
		{
			name: "Маркетинг",
			icon: BsMegaphone,
			isCollapse: true,
			items: [
				{ name: "Постеры", to: "/admin/marketing/posters", isLast: true },
			],
		},

		{ name: "Новости", icon: BsNewspaper, to: "/admin/news" },
	];

	return (
		<Box
			transition="3s ease"
			bg={useColorModeValue("black", "black")}
			boxShadow="rgb(0 0 0 / 1%) 0px 4px 6px -1px, rgb(0 0 0 / 6%) 0px 2px 4px -1px"
			w={{ base: "full", md: 60 }}
			pos="fixed"
			h="full"
			{...rest}
		>
			<Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
				<Text
					fontSize="2xl"
					fontFamily="monospace"
					fontWeight="bold"
					onClick={onClose}
				>
					<Logo to="/admin" />
				</Text>
				<CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
			</Flex>
			<Box
				style={{
					paddingInlineStart: "1.5rem",
					paddingInlineEnd: "1.5rem",
					paddingTop: "1.5rem",
					paddingBottom: "1.5rem",
					flex: "1 1 0%",
					overflow: "auto",
				}}
			>
				<List listStyleType="none">
					{LinkItems.map((link) => (
						<ListItem mb="1.25rem">
							<NavItem
								key={link.name}
								icon={link.icon}
								to={link.to}
								isHref={link.isHref}
								isCollapse={link.isCollapse}
								items={link.items}
								onClose={onClose}
							>
								{link.name}
							</NavItem>
						</ListItem>
					))}
				</List>
			</Box>
		</Box>
	);
};

const NavItem = ({
	icon,
	children,
	isHref,
	to,
	isCollapse,
	items,
	onClose,
	...rest
}) => {
	let location = useLocation();
	let url = location.pathname;
	let isActive = false;
	if (url === to) {
		isActive = true;
	}
	const { isOpen, onToggle } = useDisclosure();

	const CollapseItem = ({ item }) => {
		let location = useLocation();
		let url = location.pathname;
		let isActive = false;
		if (url === item.to) {
			isActive = true;
		}

		return (
			<HStack
				style={{ textDecoration: "none" }}
				_focus={{ boxShadow: "none" }}
				_hover={{
					color: isActive ? "main" : "gray.600",
					"text-decoration": "none",
				}}
				color={(isActive && "main") || "gray.500"}
				fontWeight={(isActive && "bold") || ""}
				fontSize="1rem"
				lineHeight="1.5rem"
				mb={item.isLast ? "0rem" : "1rem"}
				{...rest}
				onClick={onClose}
				as={RLink}
				to={item.to}
			>
				{(item.icon && <Icon mr="0.75rem" fontSize="24" as={icon} />) || (
					<Icon mr="0.75rem" fontSize="24" color="white" as={icon} />
				)}
				<Text
					style={{
						marginTop: "0px",
						marginInline: "0px",
						marginBottom: "0px",
					}}
				>
					{item.name}
				</Text>
				<Box flex="1" />
				{item.count && <Badge>{item.count}</Badge>}
			</HStack>
		);
	};

	return (
		<>
			{(!isCollapse && (
				<>
					{(!isHref && (
						<>
							{(!to && (
								<HStack
									style={{ textDecoration: "none" }}
									_focus={{ boxShadow: "none" }}
									_hover={{
										color: isActive ? "main" : "gray.600",
										"text-decoration": "none",
									}}
									color={(isActive && "main") || "gray.500"}
									fontWeight={(isActive && "bold") || ""}
									fontSize="1rem"
									onClick={onClose}
									lineHeight="1.5rem"
									{...rest}
								>
									{(icon && <Icon mr="0.75rem" fontSize="24" as={icon} />) || (
										<Icon mr="0.75rem" fontSize="24" color="white" as={icon} />
									)}
									<Text
										style={{
											marginTop: "0px",
											marginInline: "0px",
											marginBottom: "0px",
										}}
									>
										{children}
									</Text>
								</HStack>
							)) || (
								<HStack
									style={{ textDecoration: "none" }}
									_focus={{ boxShadow: "none" }}
									_hover={{
										color: isActive ? "main" : "gray.600",
										"text-decoration": "none",
									}}
									color={(isActive && "main") || "gray.500"}
									fontWeight={(isActive && "bold") || ""}
									fontSize="1rem"
									onClick={onClose}
									lineHeight="1.5rem"
									{...rest}
									as={RLink}
									to={to || "#"}
								>
									{(icon && <Icon mr="0.75rem" fontSize="24" as={icon} />) || (
										<Icon mr="0.75rem" fontSize="24" color="white" as={icon} />
									)}
									<Text
										style={{
											marginTop: "0px",
											marginInline: "0px",
											marginBottom: "0px",
										}}
									>
										{children}
									</Text>
								</HStack>
							)}
						</>
					)) || (
						<HStack
							style={{ textDecoration: "none" }}
							_focus={{ boxShadow: "none" }}
							_hover={{
								color: isActive ? "main" : "gray.600",
								"text-decoration": "none",
							}}
							color={(isActive && "main") || "gray.500"}
							fontWeight={(isActive && "bold") || ""}
							fontSize="1rem"
							lineHeight="1.5rem"
							{...rest}
							onClick={onClose}
							as={Link}
							href={to || "#"}
							isExternal
						>
							{icon && <Icon mr="0.75rem" fontSize="24" as={icon} />}
							<Text
								style={{
									marginTop: "0px",
									marginInline: "0px",
									marginBottom: "0px",
								}}
							>
								{children}
							</Text>
						</HStack>
					)}
				</>
			)) || (
				<>
					<HStack
						style={{ textDecoration: "none" }}
						_focus={{ boxShadow: "none" }}
						_hover={{
							color: isActive ? "main" : "gray.600",
							"text-decoration": "none",
							cursor: "pointer",
						}}
						color={(isActive && "main") || "gray.500"}
						fontWeight={(isActive && "bold") || ""}
						fontSize="1rem"
						lineHeight="1.5rem"
						onClick={onToggle}
						mb="1rem"
						{...rest}
					>
						{(icon && <Icon mr="0.75rem" fontSize="24" as={icon} />) || (
							<Icon mr="0.75rem" fontSize="24" color="white" as={icon} />
						)}
						<Text
							style={{
								marginTop: "0px",
								marginInline: "0px",
								marginBottom: "0px",
							}}
						>
							{children}
						</Text>
						<Icon
							transform={isOpen ? "rotate(0deg)" : "rotate(-90deg)"}
							transition="transform 0.2s ease 0s"
							focusable="false"
							xmlns="http://www.w3.org/2000/svg"
							width="24px"
							height="24px"
							viewBox="0 0 24 24"
							fill="none"
						>
							<path
								stroke="currentColor"
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-miterlimit="10"
								stroke-width="1.5"
								d="M19.92 8.95l-6.52 6.52c-.77.77-2.03.77-2.8 0L4.08 8.95"
							></path>
						</Icon>
					</HStack>
					<Collapse in={isOpen} animateOpacity>
						{items.map((item) => (
							<CollapseItem item={item} />
						))}
					</Collapse>
				</>
			)}
		</>
	);
};

const MobileNav = ({ onOpen, ...rest }) => {
	const [name, setName] = useState(null);
	const [status, setStatus] = useState(null);
	const [loaded, setLoaded] = useState(false);
	const [logout, setLogout] = useState(false);

	const exit = () => {
		Cookies.set("auth-token", "");
		setLogout(true);
	};
	const api_url = apiUrl();
	useEffect(() => {
		const loading = async () => {
			const data = await axios.get(`${api_url}/user/profile_info`, {
				headers: {
					authorization: `Bearer ${getToken()}`,
				},
			});

			if (data.data.error === 401) {
				exit();
			}

			setName(data.data.user.name);
			setStatus(data.data.user.status);
			setLoaded(true);
		};
		if (!loaded) {
			loading();
		}
	}, []);
	return (
		<Flex
			ml={{ base: 0, md: 60 }}
			px={{ base: 4, md: 4 }}
			height="20"
			alignItems="center"
			bg={useColorModeValue("black", "black")}
			justifyContent={{ base: "space-between", md: "flex-end" }}
			{...rest}
		>
			{logout && (window.location.href = "")}
			<IconButton
				display={{ base: "flex", md: "none" }}
				onClick={onOpen}
				variant="outline"
				aria-label="open menu"
				icon={<FiMenu />}
			/>

			<Text
				display={{ base: "flex", md: "none" }}
				fontSize="2xl"
				fontFamily="monospace"
				fontWeight="bold"
			>
				<Logo />
			</Text>

			<HStack spacing={{ base: "0", md: "6" }}>
				<Flex alignItems={"center"}>
					<Menu>
						<MenuButton
							py={2}
							transition="all 0.3s"
							_focus={{ boxShadow: "none" }}
						>
							<HStack>
								<Avatar size={"sm"} name={name} />
								<VStack
									display={{ base: "none", md: "flex" }}
									alignItems="flex-start"
									spacing="1px"
									ml="2"
								>
									<Text fontSize="lg">{name}</Text>
								</VStack>
								<Box display={{ base: "none", md: "flex" }}>
									<MenuButton
										as={IconButton}
										icon={<FiChevronDown />}
										variant="ghost"
									/>
								</Box>
							</HStack>
						</MenuButton>
						<MenuList
							bg={useColorModeValue("black", "black")}
							borderColor={useColorModeValue("gray.200", "gray.700")}
						>
							<MenuItem as={RLink} to="/">
								Обычная панель
							</MenuItem>
							<MenuDivider />
							<MenuItem onClick={exit}>Выйти</MenuItem>
						</MenuList>
					</Menu>
				</Flex>
			</HStack>
		</Flex>
	);
};
